import React, { useMemo } from 'react';
import ReactModal from 'react-modal';
import IconCross from '../../assets/images/icons/other/cross.inline.svg';
import useWindow from '../../hooks/useWindow';

ReactModal.setAppElement('#___gatsby');

function BasicModal({
  children,
  isOpen,
  closeHandler,
  isBottom,
  lowZIndex,
  customClassName,
  scroll,
  style,
}) {
  const { isMobile } = useWindow();

  const iconContainerClassName = useMemo(() => {
    const basicClassName = 'modal-basic__icon-container';

    return isMobile ? `${basicClassName} ${basicClassName + '-small'}` : basicClassName;
  }, [isMobile]);

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={closeHandler}
      className={customClassName}
      overlayClassName={`modal-basic__overlay ${isBottom ? 'modal-basic__overlay-bottom' : ''} ${
        lowZIndex ? 'modal-basic__overlay-low-index' : ''
      }`}
      style={style}
    >
      <div className={iconContainerClassName} onClick={closeHandler}>
        <IconCross />
      </div>
      <div className="modal-basic__content-container" style={{
        overflow: scroll ? "scroll" : null
      }}>{children}</div>
    </ReactModal>
  );
}

export default BasicModal;
