import { Link } from 'gatsby';
import React, { useState } from 'react';
import IconArrowDown from '../../assets/images/icons/other/arrow-down.inline.svg';

function MobileNestedDropdownItem({ title, subItems }) {
  const [open, setOpen] = useState(false);
  const handleClick = (e) => {
    e.stopPropagation();
    setOpen((prev) => !prev);
  };

  return (
    <div onClick={handleClick}>
      <p style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <span>{title}</span>
        <IconArrowDown
          style={{
            fill: '#62739a',
            height: '24px',
            width: '24px',
            marginLeft: '2px',
            marginTop: '-3px',
            transform: `rotate(${open ? 180 : 0}deg)`,
          }}
        />
      </p>

      {open &&
        subItems.map((subItem, idx) => (
          <Link
            key={subItem.url + idx}
            to={subItem.url}
            onClick={() => handleClick(idx)}
            // className={isSelected ? 'active' : null}
          >
            {subItem.title}
          </Link>
        ))}
    </div>
  );
}

export default MobileNestedDropdownItem;
