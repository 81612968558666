import React, {useState, useContext, useEffect} from 'react';
import imagePlayStore from '../assets/images/badges/google-play-badge (2)@3x.svg';
import imageAppStore from '../assets/images/badges/download-on-the-app-store-apple (1)@3x.svg';
import useWindow from '../hooks/useWindow';
import { LayoutContext } from './Layout';
import { BRANCH_KEY } from '../constants';

// const handlePlaystoreClick = event => {
//   trackCustomEvent({ category: "App store", action: "Click", label: "Android"});
// };

// const handleAppStoreClick = event => {
//   trackCustomEvent({ category: "App store", action: "Click", label: "IOS"});
// };

const Badges = () => {
  const { isMobile } = useWindow()
  const [sourceName, setSourceName] = useState('');
  const [refId, setRefId] = useState('');

  useEffect(() => {
    try {
      const {branchAttributionData} = useContext(LayoutContext);
      if (branchAttributionData) {
        setSourceName(branchAttributionData.advertisingPartnerName)
        setRefId(branchAttributionData.refId)
      }
    } catch (error) {
      try {
        setSourceName(localStorage.getItem('advertisingPartnerName') || 'none')
        setRefId(localStorage.getItem('branchRefId') || 'none')
      } catch (err) {
        // Add Sentry call in future.
      }
    }
  }, [])

  return (
    <div>
      <a
        href={`https://linkprod.sprive.com/fromSiteToPlayStore?source=${sourceName}&ref_id=${refId}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src={imagePlayStore} alt="play-store" />
      </a>
      <a
        href={`https://linkprod.sprive.com/fromSiteToAppStore?source=${sourceName}&ref_id=${refId}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src={imageAppStore} alt="app-store" />
      </a>
    </div>
  );
};

export default Badges;
