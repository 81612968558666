import { Link } from 'gatsby';
import React, { useCallback, useState } from 'react';
import IconArrowDown from '../../assets/images/icons/other/arrow-down.inline.svg';
import MobileNestedDropdownItem from './MobileNestedDropdownItem';

const getIsCorrectMobile = () => {
  if (typeof window !== 'undefined') {
    return window.innerWidth < 991;
  }
  return false;
};

function DropdownItem({ title, subItems, itemSelectedIndex, index, toggleHandle, isSelected }) {
  const [selectedIndex, setSelectedIndex] = useState(null);

  const handleClick = useCallback(
    (index) => {
      setSelectedIndex(index);
      toggleHandle(false);
    },
    [toggleHandle]
  );

  const isCorrectMobile = getIsCorrectMobile();

  const generateSubItems = useCallback(() => {
    const components = [];

    subItems.forEach((item, key) => {
      const isSelected = selectedIndex === key && itemSelectedIndex === index;

      components.push(
        item.isDropdown ? (
          isCorrectMobile ? (
            <MobileNestedDropdownItem {...item} />
          ) : (
            <div className="nested-dropdown-wrapper">
              <a className="title">
                {item.title} <IconArrowDown />
              </a>
              <div className="nested-dropdown">
                {item.subItems.map((el) => (
                  <Link key={el.url + key} to={el.url} className={isSelected ? 'active' : null}>
                    {el.title}
                  </Link>
                ))}
              </div>
            </div>
          )
        ) : (
          <Link
            key={item.url + key}
            to={item.url}
            onClick={() => handleClick(key)}
            className={isSelected ? 'active' : null}
          >
            {item.title}
          </Link>
        )
      );
    });
    return components;
  }, [subItems, selectedIndex, itemSelectedIndex, index]);

  return (
    <div className="dropdown-wrapper">
      <div className={`text__container ${isSelected ? "rotated" : ""}`}>
        <p>{title}</p>
        <IconArrowDown />
      </div>
      <div className={`dropdown ${isSelected ? "" : "dropdownHeight"}`}>
        <div className="dropdown-items__container">{generateSubItems()}</div>
      </div>
    </div>
  );
}

export default DropdownItem;
