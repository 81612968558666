import React from 'react'
import facebookIcon from '../../assets/images/icons/social/facebook-circle.png'
import tiktokIcon from '../../assets/images/icons/social/tiktok-circle.png'
import instagramIcon from '../../assets/images/icons/social/instagram-circle.png'
import linkedinIcon from '../../assets/images/icons/social/linkedin-circle.png'
import twitterIcon from '../../assets/images/icons/social/twitter-circle.png'
import { Link } from 'gatsby'


function LinksContainer() {
  return (
    <div className="footer__social-container">
        <a href="https://www.facebook.com/SpriveApp/" target="_blank">
            <img 
                alt="facebook icon"
                src={facebookIcon}
            />
        </a>
        <a href="https://www.tiktok.com/@spriveapp" target="_blank">
            <img 
                alt="tiktok icon"
                src={tiktokIcon}
            />
        </a>
        <a href="https://www.instagram.com/spriveapp/" target="_blank">
            <img 
                alt="instagram icon"
                src={instagramIcon}
            />
        </a>
        <a href="https://www.linkedin.com/company/sprive-com/" target="_blank">
            <img 
                alt="linkedin icon"
                src={linkedinIcon}
            />
        </a>
        <a href="https://twitter.com/spriveapp" target="_blank">
            <img
                alt="twitter icon"
                src={twitterIcon}
            />
        </a>
    </div>
  )
}

export default LinksContainer