export const BUTTON_WHITE = "button-white"
export const BUTTON_MAIN = "button-main"
export const BUTTON_MODAL = "button-modal"

export const TABLET_MIN_WIDTH = 767
export const TABLET_MAX_WIDTH = 991

export const MOBILE_MIN_WIDTH = 375

export const LINK_PREFIX =  process.env.GATSBY_CURRENT_SUBDOMAIN ? "https://sprive.com" : ""
export const PHONE_INPUT = "input-phone"
export const PHONE_REGEX = new RegExp(/^[0][1-9]\d{9}$|^[1-9](\d{9}|\d{10})$/g)
export const FULL_NAME_REGEX = new RegExp(/^[a-zA-Z]([-']?[a-zA-Z]+){1,}( [a-zA-Z]([-']?[a-zA-Z]{1,}))+$/);

export const SPRIVE_API_BASE_URL = process.env.GATSBY_SPRIVE_API_BASE_URL
export const SPRIVE_API_KEY = process.env.GATSBY_SPRIVE_API_KEY

export const MAIN_BUTTON_CLASSNAME = process.env.GATSBY_CURRENT_SUBDOMAIN ? "button-main-subdomain" : "button-main"
export const WHITE_BUTTON_CLASSNAME = process.env.GATSBY_CURRENT_SUBDOMAIN ? "button-white-subdomain" : "button-white"

export const CALENDLY_GET_EVENT_URL = process.env.GATSBY_CALENDLY_GET_EVENT_URL
// prod token
export const CALENDLY_API_TOKEN = process.env.GATSBY_CALENDLY_API_TOKEN
// development token
// export const CALENDLY_API_TOKEN = "eyJraWQiOiIxY2UxZTEzNjE3ZGNmNzY2YjNjZWJjY2Y4ZGM1YmFmYThhNjVlNjg0MDIzZjdjMzJiZTgzNDliMjM4MDEzNWI0IiwidHlwIjoiUEFUIiwiYWxnIjoiRVMyNTYifQ.eyJpc3MiOiJodHRwczovL2F1dGguY2FsZW5kbHkuY29tIiwiaWF0IjoxNjgxMTMzODI0LCJqdGkiOiI0NzY1Y2I1MS1lODYzLTRhYzgtYTQ0My0wYzcwMGZjZDFkMjgiLCJ1c2VyX3V1aWQiOiIxYzc2ZDU4My02NDk0LTRhZDctYjA0My05Mzc0NTg4NTg2ZmEifQ.N2bA8RTxgCXhooaTdFW--dY8OOtjFOrsZ9msEv4kuyBpcZM542d-n5lTU0vGPP9aXhvkNPjQvDSHIxFRNm_OWA"

export const BRANCH_KEY = process.env.GATSBY_BRANCH_KEY

export const DESTINATION_ECHO = "Echo"
export const HOVER_COLOR = '#da2972'
