import React from 'react';

import { ContextProviderComponent } from './Context';
import Header from './Header';
import Footer from './Footer/Footer';
import CookieBanner from './CookieBanner';
import { createContext } from 'react';
import { useState, useEffect } from 'react';

import { BRANCH_KEY } from '../constants';

export const LayoutContext = createContext(null)

const Layout = ({ children, uri }) => {
  const [cookieAgree, setCookieAgree] = useState(true);
  const [footerContent, setFooterContent] = useState("Connect");
  const [branchAttributionData, setBranchAttributionData] = useState({advertisingPartnerName: 'none', refId: 'none'});

  useEffect(() => {
    try {
      // Fetch Branch data
      if (branch) {
        branch.init(BRANCH_KEY, function (err, data) {
          if (data) {
            setBranchAttributionData({
              advertisingPartnerName: data.data_parsed?.['~advertising_partner_name'] || 'organic',
              refId: data.data_parsed?.['~id'] || 'none_from_branch'
            });
            localStorage.setItem('advertisingPartnerName', data.data_parsed?.['~advertising_partner_name'] || 'organic');
            localStorage.setItem('branchRefId', data.data_parsed?.['~id'] || 'none_from_branch');
          }
        });
      }
    } catch (err) {
      // Add Sentry call in future.
    }
  }, [])

  return (
    <>
      <ContextProviderComponent>
        <Header isMainPage={uri === '/'} />
        <LayoutContext.Provider value={{
          cookieAgree,
          setFooterContent,
          branchAttributionData
        }}>
          <main>{children}</main>
        </LayoutContext.Provider>
        <Footer connectText={footerContent} />
        {/*Not showing this Cookie banner now, as relying on CookieBot dynamic consent banner*/}
        {/*<CookieBanner cookieAgree={cookieAgree} setCookieAgree={setCookieAgree}/>*/}
      </ContextProviderComponent>
    </>
  );
};

export default Layout;
