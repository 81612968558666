import React from 'react';
import DownloadQR from '../../assets/images/icons/other/download-app-qr.inline.svg';
import useWindow from '../../hooks/useWindow';
import { SecondaryDescription, SecondaryTitle } from '../Typography';
import ImageLogo from '../../assets/images/logo/logo-arrow.inline.svg';
import Badges from '../Badges';

function DownloadAppModal() {
  const { isMobile } = useWindow();

  return (
    <div className="modal__app-download">
      {!isMobile ? (
        <DownloadQR />
      ) : (
        <div>
          <ImageLogo />
        </div>
      )}
      <div className="modal__app-download-text">
        <SecondaryTitle fontSize={!isMobile ? '32px' : '30px'}>
          {!isMobile ? (
            <>
              Download
              <span>Sprive</span>
            </>
          ) : (
            <>Download Now</>
          )}
        </SecondaryTitle>
        <SecondaryDescription fontSize="18px" color={!isMobile ? '#AEAEAE' : '#4C5D89'}>
          {!isMobile ? (
            <>Scan the QR Code to download the app now</>
          ) : (
            <>Sprive supports 13 of the largest lenders in the UK.</>
          )}
        </SecondaryDescription>
          <div className="modal__app-download-badges">
            <Badges />
          </div>
      </div>
    </div>
  );
}

export default DownloadAppModal;
