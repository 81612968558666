import React from 'react'

export function MainTitle({children, ...rest}) {
  return (
    <h1
        className="typography__title typography__title-main"
        style={{...rest}}
    >
        {children}
    </h1>
  )
}

export function SecondaryTitle({children, ...rest}) {
    return (
      <h2
          className="typography__title typography__title-secondary"
          style={{...rest}}
      >
          {children}
      </h2>
    )
}

export function SubTitle({children, ...rest}) {
    return (
      <h3
          className="typography__title typography__title-sub"
          style={{...rest}}
      >
          {children}
      </h3>
    )
}

export function SubTitleSmall({children, ...rest}) {
    return (
      <h3
          className="typography__title typography__title-sub typography__title-sub-small"
          style={{...rest}}
      >
          {children}
      </h3>
    )
}

export function ModalTitle({children, ...rest}) {
    return (
      <h3
          className="typography__title typography__title-modal"
          style={{...rest}}
      >
          {children}
      </h3>
    )
}

export function MainDescription({children, ...rest}) {
    return (
      <p
          className="typography__description typography__description-main"
          style={{...rest}}
      >
          {children}
      </p>
    )
}

export function SecondaryDescription({children, ...rest}) {
    return (
      <p
          className="typography__description typography__description-secondary"
          style={{...rest}}
      >
          {children}
      </p>
    )
}

export function ModalDescription({children, ...rest}) {
    return (
      <p
          className="typography__description typography__description-modal"
          style={{...rest}}
      >
          {children}
      </p>
    )
}
