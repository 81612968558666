import { Link, navigate } from 'gatsby';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import ContextConsumer from '../Context';

import IconCross from '../../assets/images/icons/other/cross.inline.svg';
import IconMenu from '../../assets/images/icons/other/menu.inline.svg';
import ImageLogo from '../../assets/images/logo/logo.inline.svg';

import { BUTTON_WHITE, LINK_PREFIX } from '../../constants';
import useMobileNavigation from '../../hooks/useMobileNavigation';
import useWindow from '../../hooks/useWindow';
import Button from '../Button';
import BasicModal from '../Modals/BasicModal';
import DownloadAppModal from '../Modals/DownloadAppModal';
import { Menu } from './Menu';

function Header({ dataContext, setContext }) {
  const [isClient, setClient] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [stickHeader, setStickHeader] = useState(false);
  const [userEmail, setUserEmail] = useState('');
  const [isDropDownOpen, setIsDropDownOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [firstModalOpen, setFirstModalOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(null);

  const { isMobile } = useWindow();

  const key = isClient ? `client` : `server`;

  const toggleHandle = (value, anchor) => {
    setToggle(value);

    if (anchor) {
      navigate('/#trust-and-security');
    }
  };

  const handleScroll = () => {
    const scrolled = window.pageYOffset || document.documentElement.scrollTop;
    setStickHeader(scrolled > 60);
  };

  const handleLogoutUser = () => {
    localStorage.removeItem('auth-user');
    setContext({ isAuthUser: false });
    setToggle(false);
  };

  const handleModalClose = useCallback(() => {
    setIsModalOpen(false)
  }, [])

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  });

  useEffect(() => {
    if (isMobile && firstModalOpen) {
      useMobileNavigation();
    }

    if (isMobile && isModalOpen) {
      setFirstModalOpen(true)
      setIsModalOpen(false)
    }
  }, [isMobile, isModalOpen, firstModalOpen])

  const getHeaderStyles = () => {
    let result = 'header';
    if (toggle) {
      result = `${result} mobile-open`;
    }
    if (stickHeader) {
      result = `${result} header-white`;
    }
    return result;
  };

  const dropdownWrapperClassName = useMemo(() => {
    const baseClassName = "links__wrapper"

    return !isDropDownOpen ? baseClassName : `${baseClassName} open`
  }, [isDropDownOpen])

  useEffect(() => {
    const userLocalEmail = localStorage.getItem('auth-user');
    if (userLocalEmail) {
      setUserEmail(userLocalEmail);
    }
  }, [dataContext.isAuthUser]);

  useEffect(() => {
    setClient(true);
  }, []);

  return (
    <header className={getHeaderStyles()} key={key}>
      <div className="container">
        <div className="wrapper desktop">
          <Link
            to={LINK_PREFIX || "/"}
            target={process.env.GATSBY_CURRENT_SUBDOMAIN && "_blank"}
            title="Sprive"
            aria-label="Sprive"
            className="header__logo"
            onClick={() => setSelectedIndex(null)}
          >
            <ImageLogo />
          </Link>

          <div
            className="header__wrapper"
            style={{ alignItems: dataContext.isAuthUser ? 'flex-end' : 'baseline' }}
          >
            <nav className="header__nav">
              <ul>
                <Menu
                  toggleHandle={toggleHandle}
                  setIsDropDownOpen={() => {}}
                  selectedIndex={selectedIndex}
                  setSelectedIndex={setSelectedIndex}
                  />
              </ul>
            </nav>

            {dataContext.isAuthUser ? (
              <div className="header__user">
                <span>Hi {userEmail} </span>
                <span className="link" onClick={handleLogoutUser}>
                  Not you?
                </span>
              </div>
            ) : (
              <div className="header__soon">
                <Button
                  buttonText="Download App"
                  variant={BUTTON_WHITE}
                  handleClick={() => setIsModalOpen(true)}
                  height="55px"
                  fontSize="16px"
                  padding="0 32px"
                />
              </div>
            )}
          </div>
        </div>

        <div className="mobile">
          <div className="mobile__top">
            <div className="mobile__top__wrapper">
              <Link to="/" className="mobile__top__logo">
                <ImageLogo onClick={() => toggleHandle(false)} />
              </Link>
              <div className="mobile__top__button-container">
                <Button
                  buttonText="Download App"
                  variant={BUTTON_WHITE}
                  handleClick={() => setIsModalOpen(true)}
                />
                <div onClick={() => toggleHandle(!toggle)}>
                  {toggle ? <IconCross className="icon-menu" /> : <IconMenu className="icon-menu" />}
                </div>
              </div>
            </div>
          </div>
          <div className="mobile__content">
            <div className="mobile__content__user">
              {dataContext.isAuthUser && (
                <div className="d-flex">
                  <span>Hi {userEmail} </span>
                  <span className="link" onClick={handleLogoutUser}>
                    Forget me
                  </span>
                </div>
              )}
            </div>
            <div className={dropdownWrapperClassName}  style={{height: "calc(100% - 64px)", overflow:"scroll"}}>
              <div className="mobile__content__nav">
                <nav>
                  <ul >
                    <Menu
                      toggleHandle={toggleHandle}
                      isDropDownOpen={isDropDownOpen}
                      setIsDropDownOpen={setIsDropDownOpen}
                      selectedIndex={selectedIndex}
                      setSelectedIndex={setSelectedIndex}
                    />
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
      <BasicModal
        isOpen={!isMobile && isModalOpen}
        closeHandler={handleModalClose}
        style={{
          content: {
            height: isMobile ? '272px' : '557px',
          },
        }}
      >
        <DownloadAppModal />
      </BasicModal>
    </header>
  );
}

const HeaderContext = (props) => (
  <ContextConsumer>
    {({ data, set }) => <Header dataContext={data} setContext={set} {...props} />}
  </ContextConsumer>
);

export default HeaderContext;
