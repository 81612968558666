import {navigate} from 'gatsby';

export default function useMobileNavigation() {
  const sourceName = localStorage.getItem('advertisingPartnerName') || 'none';
  const refId = localStorage.getItem('branchRefId') || '';

  if (navigator.userAgent.toLowerCase().indexOf("android") > -1)
    navigate(`https://linkprod.sprive.com/fromSiteToPlayStore?source=${sourceName}&ref_id=${refId}`)
  else if (navigator.userAgent.toLowerCase().indexOf("iphone") > -1)
    navigate(`https://linkprod.sprive.com/fromSiteToAppStore?source=${sourceName}&ref_id=${refId}`)
}
