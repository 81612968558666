import { Link } from 'gatsby';
import React, { useCallback, useEffect, useState } from 'react';
import { LINK_PREFIX } from '../../constants';
import useMobileNavigation from '../../hooks/useMobileNavigation';
import useWindow from '../../hooks/useWindow';
import DropdownItem from './DropdownItem';

const menuItems = [
  {
    title: 'Remortgage',
    url: `${LINK_PREFIX}/mortgages/remortgage`,
  },
  {
    title: 'Calculators',
    url: '/',
    isDropdown: true,
    subItems: [
      {
        title: 'Overpayment Calculator',
        url: `${LINK_PREFIX}/mortgages/overpayment-calculator`,
      },
      {
        title: 'Shop with Sprive',
        url: `${LINK_PREFIX}/mortgages/cashback-calculator`,
      },
    ],
  },
  {
    title: 'About Us',
    url: `/`,
    isDropdown: true,
    subItems: [
      {
        title: 'About Sprive',
        url: `${LINK_PREFIX}/about`,
      },
      {
        title: 'About Jinesh - CEO',
        url: `${LINK_PREFIX}/aboutJinesh`,
      },
    ],
  },
  {
    title: 'Learn',
    url: `/`,
    isDropdown: true,
    subItems: [
      {
        title: 'Sprive Academy',
        url: `${LINK_PREFIX}/academy`,
      },
      {
        title: 'Blogs',
        url: `${LINK_PREFIX}/blog`,
      },    
      // {
      //   title: 'Mortgage Guides',
      //   isDropdown: true,
      //   subItems: [
      //     {
      //       title: 'Flexible Mortgages',
      //       url: `${LINK_PREFIX}/mortgages/flexible-mortgage`,
      //     },
      //     {
      //       title: 'Tracker Mortgages',
      //       url: `${LINK_PREFIX}/mortgages/tracker`,
      //     },
      //     {
      //       title: 'Discounted Mortgages',
      //       url: `${LINK_PREFIX}/mortgages/discount`,
      //     },
      //     {
      //       title: 'Fixed Mortgages',
      //       url: `${LINK_PREFIX}/mortgages/fixed-rate`,
      //     },
      //     {
      //       title: 'Variable Mortgages',
      //       url: `${LINK_PREFIX}/mortgages/variable-rate`,
      //     },
      //   ],
      // },
    ],
  },
  {
    title: 'Get In Touch',
    url: `/`,
    isDropdown: true,
    subItems: [
      {
        title: 'Get In Touch',
        url: `${LINK_PREFIX}/get-in-touch`,
      },
      {
        title: 'FAQ',
        url: `${LINK_PREFIX}/faq`,
      },
    ],
  },

  // {
  //   title: 'Get In Touch',
  //   url: `${LINK_PREFIX}/get-in-touch`,
  // },
  // {
  //   title: 'FAQ',
  //   url: `${LINK_PREFIX}/faq`,
  // },
];

export const Menu = ({ toggleHandle, setIsDropDownOpen, selectedIndex, setSelectedIndex }) => {
  const { isTablet, isMobile } = useWindow();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [firstModalOpen, setFirstModalOpen] = useState(false);

  const handleClick = useCallback(
    (index) => {
      toggleHandle(false);
      setSelectedIndex(index);
    },
    [toggleHandle]
  );

  const generateMenuItems = useCallback(() => {
    const components = [];

    menuItems.forEach((item, key) => {
      const isSelected = selectedIndex === key;
      components.push(
        <>
          {item?.isDropdown ? (
            <li
              key={Math.random()}
              onClick={() => {
                setIsDropDownOpen((prevState) => !prevState);
                setSelectedIndex(key)
              }}
              className={isSelected ? 'active' : null}
            >
              <DropdownItem
                title={item.title}
                subItems={item.subItems}
                itemSelectedIndex={selectedIndex}
                index={key}
                toggleHandle={toggleHandle}
                isSelected={isSelected}
              />
            </li>
          ) : (
            <li key={item.title + key}>
              {process.env.GATSBY_CURRENT_SUBDOMAIN ? (
                <a
                  href={item.url}
                  target="_blank"
                  onClick={() => handleClick(key)}
                  className={isSelected ? 'active' : null}
                  title={item.title}
                >
                  {item.title}
                </a>
              ) : (
                <Link
                  to={item.url}
                  onClick={() => handleClick(key)}
                  className={isSelected ? 'active' : null}
                  title={item.title}
                >
                  {item.title}
                </Link>
              )}
            </li>
          )}
        </>
      );
    });

    return components;
  }, [selectedIndex]);

  useEffect(() => {
    if (isMobile && firstModalOpen) {
      useMobileNavigation();
    }

    if (isMobile && isModalOpen) {
      setFirstModalOpen(true);
      setIsModalOpen(false);
    }
  }, [isMobile, isModalOpen, firstModalOpen]);

  return (
    <>
      {generateMenuItems()}
      {(isMobile || isTablet) && (
        <div className="download__container" onClick={() => setIsModalOpen(true)}>
          <p>Download App</p>
        </div>
      )}
    </>
  );
};
